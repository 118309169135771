import React from "react";

import Image from "next/image";

import GithubIcon from "../../assets-protocol-v4/image-component/github-icon";
import LinkedinIcon from "../../assets-protocol-v4/image-component/linkedin-icon";
import XIcon from "../../assets-protocol-v4/image-component/x-icon";
import { trackUserEvent } from "../../utils/analytics";
import { RedirectLinks } from "../../utils/common";
import FooterButton from "../FooterButton";

interface ILinks {
  id: number;
  label: string;
  onClick: () => void;
  icon: JSX.Element | null;
  linkId?: any;
}
interface IFooterItems {
  id: number;
  links: ILinks[];
}

const Footer = () => {
  const footerItems: IFooterItems[] = [
    {
      id: 1,
      links: [
        {
          id: 1,
          label: "Provider",
          onClick: () => window.open(RedirectLinks.PROVIDER_APP),
          icon: null,
        },
        {
          id: 2,
          label: "Fizz Node",
          onClick: () => window.open(RedirectLinks.FIZZ_NODE_APP),
          icon: null,
        },
        {
          id: 3,
          label: "Console",
          onClick: () => window.open(RedirectLinks.CONSOLE_APP),
          icon: null,
        },
        {
          id: 4,
          label: "CLI",
          onClick: () => window.open(RedirectLinks.CLI_DOCS),
          icon: null,
        },
        // {
        //   id: 4,
        //   label: "Auth",
        //   onClick: () => window.open(RedirectLinks.APP_LOGIN),
        //   icon: null,
        // },
      ],
    },
    {
      id: 2,
      links: [
        {
          id: 1,
          label: "Media Kit",
          onClick: () => window.open(RedirectLinks.MAREKTINGKIT),
          icon: null,
        },
        {
          id: 2,
          label: "Changelog",
          onClick: () => window.open(RedirectLinks.CHANGELOG),
          icon: null,
        },
        // {
        //   id: 3,
        //   label: "Join Community",
        //   onClick: () => window.open(RedirectLinks.COMMUNITY),
        //   icon: null,
        // },
        {
          id: 4,
          label: "Blog",
          onClick: () => window.open(RedirectLinks.BLOG),
          icon: null,
        },
      ],
    },
    {
      id: 3,
      links: [
        {
          id: 1,
          label: "Youtube",
          onClick: () => window.open(RedirectLinks.YOUTUBE),
          icon: null,
        },
        {
          id: 2,
          label: "Linkedin",
          onClick: () => window.open(RedirectLinks.LINKEDIN),
          icon: null,
        },
        {
          id: 3,
          label: "Discord",
          onClick: () => window.open(RedirectLinks.DISCORD),
          icon: null,
        },
        {
          id: 4,
          label: "X",
          onClick: () => window.open(RedirectLinks.TWITTER),
          icon: null,
        },
      ],
    },
  ];

  const list = (link: ILinks) => (
    <li
      key={link.id}
      onClick={() => {
        trackUserEvent(`Footer Link ${link.label} Click`);
        link.onClick();
      }}
      className="flex items-center justify-start capitalize cursor-pointer gap-1.5
       text-transparent hover:text-protocolNew-base font-sans"
    >
      <span>&bull;</span>
      {!!link.icon && <span>{link.icon}</span>}
      <div className="flex items-center text-protocolNew-sub-heading hover:text-protocolNew-base">
        {link.label}
      </div>
    </li>
  );

  const twitterText = process.env.NEXT_PUBLIC_TWITTER_TEXT || "";
  const githubText = process.env.NEXT_PUBLIC_GITHUB_TEXT || "";
  const linkedinText = process.env.NEXT_PUBLIC_LINKEDIN_TEXT || "";

  const footerButtons = [
    {
      id: 1,
      title: "X",
      text: twitterText,
      onClick: () => window.open(RedirectLinks.TWITTER),
      icon: <XIcon />,
    },
    {
      id: 2,
      title: "Github",
      text: githubText,
      onClick: () => window.open(RedirectLinks.GITHUB),
      icon: <GithubIcon />,
    },
    {
      id: 3,
      title: "LinkedIn",
      text: linkedinText,
      onClick: () => window.open(RedirectLinks.LINKEDIN),
      icon: <LinkedinIcon />,
    },
  ];

  return (
    <section>
      <hr className="h-[1px] w-full bg-protocolNew-border" />
      <div className="flex justify-center items-center">
        <div
          className="flex gap-4 lg:flex-row flex-col w-full lg:mx-sides text-center lg:text-left
          pb-4 lg:pb-6 border-x lg:border-protocolNew-border pt-2 px-2 relative"
        >
          <div className="lg:basis-2/5 font-jetbrains text-sm font-normal text-protocolNew-footer-subtext">
            Powered by{" "}
            <span className="text-black opacity-80">Spheron Network</span>
          </div>
          <div className="flex flex-row w-full justify-between lg:justify-evenly">
            {footerItems.map((item) => (
              <div key={item.id} className="flex flex-col">
                <ul className="space-y-2 font-normal text-xs font-sans">
                  {item.links.map((link) => (
                    <React.Fragment key={link.label + link.id}>
                      {list(link)}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr className="h-[1px] w-full bg-protocolNew-border" />
      <div
        className="flex flex-col gap-3 items-center lg:flex-row lg:justify-between
        space-y-2 font-normal text-xs font-sans text-protocolNew-sub-heading py-4
        px-6 lg:mx-sides w-content-mobile lg:w-content"
      >
        <div>
          <a
            onClick={() => trackUserEvent("Footer Privacy Policy Link Click")}
            href="./Spheron_Website_Terms_of_Use.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          .{" "}
          <a
            onClick={() =>
              trackUserEvent("Footer Terms of Conditions Link Click")
            }
            href="./Spheron_Website_Terms_of_Use.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Conditions
          </a>
        </div>
        <div className="flex flex-row gap-2">
          {footerButtons.map((buttonContent) => (
            <FooterButton
              key={buttonContent.id}
              onClick={() => {
                trackUserEvent(`Footer Social ${buttonContent.text} Click`);
                buttonContent.onClick();
              }}
              className="w-max text-protocolNew-base
              hover:bg-protocolNew-base hover:text-white 
               duration-300 ease-out"
            >
              <div className="flex gap-2 items-center text-xs font-normal">
                {buttonContent.icon}
                <span>{buttonContent.text}</span>
              </div>
            </FooterButton>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Footer;
