import * as amplitude from "@amplitude/analytics-browser";

export interface NodeData {
  activeCpus: number;
  activeGpus: number;
  activeNodes: number;
  date: string;
  ram: number;
  storage: number;
  timestamp: number;
  totalActiveAppleChips: number;
  totalAppleChips: number;
  totalCpus: number;
  totalGpus: number;
  totalNodes: number;
  totalPayouts: string;
  uniqueRegions: string[];
  cumulativePayouts: string;
}

export const trackUserEvent = (
  event: string,
  properties?: Record<string, any>
) => {
  amplitude.track(event, properties);
};

export const addCommasToNumber = (num: number): string => {
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumber = (
  value: number,
  opts?: { roundOff?: boolean }
): string => {
  const suffixes = ["", "K", "M", "B"];
  let i = 0;

  while (value >= 1000 && i < suffixes.length - 1) {
    value /= 1000;
    i++;
  }

  const formattedValue =
    value % 1 === 0 || opts?.roundOff
      ? Math.round(value)
      : parseFloat(value?.toFixed(1));

  return formattedValue + suffixes[i];
};

export const getPercentage = (lastData: number, currentData: number) =>
  (((lastData - currentData) / currentData) * 100).toFixed(1);

export const calculateTimeDifferenceFromLast = (data: any) => {
  if (data) {
    const sortedData = data?.sort(
      (a: any, b: any) => a.timestamp - b.timestamp
    );

    // Get the last object (most recent)
    const lastObject = sortedData[sortedData?.length - 1];

    return sortedData
      .filter((current: any) => {
        const timeDifference =
          (lastObject.timestamp - current.timestamp) / (1000 * 60 * 60 * 24);
        return timeDifference > 6 && timeDifference < 8;
      })
      .map((current: any) => {
        const timeDifference =
          (lastObject.timestamp - current.timestamp) / (1000 * 60 * 60 * 24);

        const percentChangePayouts = getPercentage(
          parseFloat(lastObject.cumulativePayouts),
          parseFloat(current.cumulativePayouts)
        );

        const percentChangeCpus = getPercentage(
          lastObject.totalCpus,
          current.totalCpus
        );

        const percentChangeGpus = getPercentage(
          lastObject.totalGpus,
          current.totalGpus
        );

        const percentChangeNodes = getPercentage(
          lastObject.totalNodes,
          current.totalNodes
        );

        const percentChangeRam = getPercentage(lastObject.ram, current.ram);

        const percentChangeRegion = getPercentage(
          lastObject.uniqueRegions.length,
          current.uniqueRegions.length
        );

        const percentChangeStorage = getPercentage(
          lastObject.storage,
          current.storage
        );

        const percentChangeApple = getPercentage(
          lastObject.totalAppleChips,
          current.totalAppleChips
        );

        return {
          timeDifferenceInDays: timeDifference.toFixed(1),
          percentChangePayouts: parseFloat(percentChangePayouts),
          percentChangeCpus: parseFloat(percentChangeCpus),
          percentChangeGpus: parseFloat(percentChangeGpus),
          percentChangeNodes: parseFloat(percentChangeNodes),
          percentChangeRam: parseFloat(percentChangeRam),
          percentChangeRegion: parseFloat(percentChangeRegion),
          percentChangeStorage: parseFloat(percentChangeStorage),
          percentChangeApple: parseFloat(percentChangeApple),
        };
      });
  } else
    return {
      timeDifferenceInDays: 0,
      percentChangePayouts: 0,
      percentChangeCpus: 0,
      percentChangeGpus: 0,
      percentChangeNodes: 0,
      percentChangeRam: 0,
      percentChangeRegion: 0,
      percentChangeStorage: 0,
      percentChangeApple: 0,
    };
};
