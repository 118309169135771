import { useState } from "react";

import { InferGetServerSidePropsType, NextPage } from "next";

import Meta from "../components/Meta";
import BusinessAndDeveloperSection from "../components-protocol-v4/BusinessAndDeveloperSection";
import ComputeMarketplaceSection from "../components-protocol-v4/ComputeMarketplaceSection";
import Dot from "../components-protocol-v4/Dot";
import EcosystemSuiteSection from "../components-protocol-v4/EcosystemSuiteSection";
import FizzNodeModal from "../components-protocol-v4/FizzNodeModal";
import Footer from "../components-protocol-v4/Footer";
import HeroSection from "../components-protocol-v4/HeroSection";
import JoinProvidersNetworkSection from "../components-protocol-v4/JoinProvidersNetworkSection";
import NavBar from "../components-protocol-v4/NavBar";
import { NavbarItems } from "../components-protocol-v4/NavBar/Navbar";
import Partners from "../components-protocol-v4/Partners";
import ProviderSection from "../components-protocol-v4/ProvidersSection";
import { RedirectLinks, showFizzNotifyModal } from "../utils/common";
import { mainMeta } from "../utils/meta-data";
import {
  fetchInventory,
  fetchTotalLeases,
  fetchTotalRewards,
} from "../utils/resources";

export const getServerSideProps = async () => {
  // const inventory = await fetchInventory();

  // const totalRewards = await fetchTotalRewards();

  // const combinedTotalRewards =
  //   Number(totalRewards.totalFizzRewards || 0) / 10 ** 18 +
  //   Number(totalRewards.totalProviderRewards || 0) / 10 ** 18;
  const combinedTotalRewards = 830000000;

  // const totalLeases = await fetchTotalLeases();

  return {
    props: {
      totalTFlops: 118208,
      totalRewards: combinedTotalRewards,
      totalLeases: 5975,
    },
  };
};

const HomeV4 = ({
  totalTFlops,
  totalRewards,
  totalLeases,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const [showFizzNodeNotifyModal, setShowFizzNodeNotifyModal] = useState(() =>
    showFizzNotifyModal()
  );

  return (
    <main className="p-2 lg:p-5 bg-[#F0F0F3]">
      <Meta
        title={mainMeta.title}
        desc={mainMeta.desc}
        keyword={mainMeta.keyword}
        icon={mainMeta.icon}
        metaImage={mainMeta.metaImage}
        url={mainMeta.url}
      />
      <div className="bg-[#141416] p-4 rounded font-sans text-white text-center">
        The checker page for the $SPON Genesis Drop is now live!{" "}
        <a
          href={RedirectLinks.SPON_ELIGIBILITY_PAGE}
          rel="noreferrer"
          className="underline"
          target="_blank"
        >
          Check your eligibility
        </a>
      </div>
      <div className="isolate bg-white rounded-md pb-20 lg:pb-0">
        <NavBar
          page={NavbarItems.HOME}
          buttonContent="Rent Now 🚀"
          buttonOnClick={() => window.open(RedirectLinks.CONSOLE_APP, "_blank")}
        />

        <HeroSection
          cardData={{
            totalRewards,
            totalTFlops,
            totalLeases,
          }}
        />
        <Partners />

        <div className="relative w-full h-full flex flex-col gap-y-44 pb-24 lg:pb-20 lg:!mt-0">
          <div className="absolute lg:mx-sides hidden lg:flex justify-center border-x lg:w-content h-full">
            <Dot className="hidden lg:flex top-0 left-0 -ml-1 -translate-y-1" />
            <Dot className="hidden lg:flex top-0 right-0 -mr-1 -translate-y-1" />
            <Dot className="hidden lg:flex bottom-0 left-0 -ml-1 translate-y-1" />
            <Dot className="hidden lg:flex bottom-0 right-0 -mr-1 translate-y-1" />
            <div className="w-1/3 border-x relative">
              <Dot className="hidden lg:flex top-0 left-0 -ml-1 -translate-y-1" />
              <Dot className="hidden lg:flex top-0 right-0 -mr-1 -translate-y-1" />
              <Dot className="hidden lg:flex bottom-0 left-0 -ml-1 translate-y-1" />
              <Dot className="hidden lg:flex bottom-0 right-0 -mr-1 translate-y-1" />
            </div>
          </div>
          <div className="absolute mx-6 lg:hidden flex justify-center border-x w-content-mobile h-full">
            <div className="w-1/3 border-x" />
          </div>
          <ComputeMarketplaceSection />
          <BusinessAndDeveloperSection />
          <EcosystemSuiteSection />
          <ProviderSection />
          <JoinProvidersNetworkSection />
        </div>

        <Footer />
        {/* {showFizzNodeNotifyModal && (
          <FizzNodeModal close={() => setShowFizzNodeNotifyModal(false)} />
          // <FizzNotifyModal close={() => setShowFizzNodeNotifyModal(false)} />
        )} */}
      </div>
    </main>
  );
};

export default HomeV4;
